<!--
 * @Descripttion: 
 * @version: 
 * @Author: 王三华
 * @Date: 2023-06-25 16:57:20
-->
<template>
  <div class="page">
    <div class="topimg">
      <img :src="detailData.actCover " alt="" />
    </div>
    <div class="content">
      <div class="title">活动介绍</div>
      <div v-html="detailData.actExplain" class="contenttext">

      </div>
    </div>
  </div>
</template>

<script>
import * as act from '@/api/bonus.js'
export default {
  data(){
    return{
      thepkId:"",
      detailData:{}
    }
  },
  created(){
    this.thepkId = this.$route.query.pkId
    this.getDetail()
  },
  methods:{
    getDetail(){
      act.getActivityDetailList({pkId:this.thepkId}).then(res=>{
       this.detailData = res.rows[0]
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.page {
  height: 100%;
  // background: #a70c02;
  .topimg {
    width: 100%;
    // height: 813px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .content {
    text-align: center;
    background: #a70c02;
    padding: 68px 400px;
    .title {
      font-size: 48px;

      font-weight: 400;
      color: #fbf0cf;
    }
    .contenttext {
      font-size: 22px;
      font-weight: 400;
      color: #fbf0cf;
      line-height: 26px;
      margin-top: 40px;
      word-wrap: break-word;
    }
  }
}
</style>